import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../../../../commons/selectors/environment'
import {getComponentSettings} from '../../../../../selectors/component'
import {getContentAlignment} from '../../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {
  getSelectedTicketDonation,
  getSelectedTicketQuantity,
  getSelectedTicketDonationError,
} from '../../../../../selectors/selected-tickets'
import {isDonationInputVisible} from '../../../../../selectors/tickets-picker'
import {PriceOwnProps, PriceRuntimeProps} from './interfaces'
import {Price as Presentation} from './price'

export const mapRuntime = (
  {state, actions: {changeTicketDonation, selectTicket, setDonationError}}: DetailsPageAppProps,
  {ticket}: PriceOwnProps,
): PriceRuntimeProps => {
  const settings = getComponentSettings(state)

  return {
    isMobile: isMobile(state),
    quantity: getSelectedTicketQuantity(state, ticket.id),
    donation: getSelectedTicketDonation(state, ticket.id),
    donationError: getSelectedTicketDonationError(state, ticket.id),
    contentAlignment: getContentAlignment(settings),
    donationInputVisible: isDonationInputVisible(state, ticket),
    changeTicketDonation,
    selectTicket,
    setDonationError,
  }
}

export const Price = withTranslation()(connect<PriceOwnProps, PriceRuntimeProps>(mapRuntime)(Presentation))
export * from './interfaces'
