import React from 'react'
import {getFormattedMoney, getFormattedPrice} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import s from '../order-summary.scss'
import {TicketProps} from './index'

export const Ticket = ({ticket, t}: TicketProps) => {
  const totalPrice = getFormattedPrice(ticket.orderedCount * Number(ticket.price.amount), ticket.price.currency)
  const price = getFormattedMoney(ticket.price)
  const quantity = ticket.orderedCount

  return (
    <div className={s.ticketInfo}>
      <div className={s.ticketName}>{ticket.name}</div>
      <div className={classNames(s.secondaryText, s.smallText, s.ticketPrice)}>{t('mobile.thanks.price', {price})}</div>
      <div className={s.spreadRow}>
        <div className={classNames(s.secondaryText, s.smallText)}>{t('mobile.thanks.quantity', {quantity})}</div>
        <div>{totalPrice}</div>
      </div>
      <div className={s.divider} />
    </div>
  )
}
