import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Badge} from '../../../../badge'
import {SaleEndedBadgeProps} from './index'

export const SaleEndedBadge = ({className}: SaleEndedBadgeProps) => {
  const {t} = useTranslation()

  return (
    <Badge className={className} data-hook={DH.TICKET_SALE_ENDED}>
      <p>{t('ticketsPicker.saleEndedBadge')}</p>
    </Badge>
  )
}
