import classNames from 'classnames'
import React from 'react'
import {SIDE_BY_SIDE_HEADER_IMAGE_SIZES} from '../../../../../../../../commons/utils/image'
import {EventImage} from '../../../../event-image'
import s from './image.scss'
import {ImageProps} from './interfaces'

export const Image: React.FC<ImageProps> = ({mainImage, crop, fullWidth}) => {
  const imageSize = fullWidth ? SIDE_BY_SIDE_HEADER_IMAGE_SIZES.FULL_WIDTH : SIDE_BY_SIDE_HEADER_IMAGE_SIZES.MINIMIZED
  const container = crop
    ? {height: imageSize, width: imageSize}
    : {height: mainImage.height || imageSize, width: mainImage.width || imageSize}

  return <EventImage className={classNames(s.imageContainer, {[s.minimized]: !fullWidth})} container={container} />
}
