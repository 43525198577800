import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import c from '../../../../classnames.scss'
import s from './sale-ends-label.scss'
import {SaleEndsLabelProps} from './index'

export const SaleEndsLabel = ({endDate, className}: SaleEndsLabelProps) => {
  const {t} = useTranslation()

  return (
    <div
      className={classNames(c.evTicketAdditionalInfoColor, s.saleEndsLabel, className)}
      role="cell"
      aria-label={`${t('ticketsPicker.saleEnds')}: ${endDate}`}
      data-hook={DH.TICKET_SALE_ENDS}
    >
      <p>{t('ticketsPicker.saleEnds')}</p>
      <p>{endDate}</p>
    </div>
  )
}
