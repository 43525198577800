import React, {Context} from 'react'
import {typedConnect} from '../../../../../commons/components/runtime-context/connect'
import {RuntimeContext} from '../../../../../commons/components/runtime-context/runtime-context'
import {State} from '../../types'
import {DetailsPageAppProps} from '../app/interfaces'

export const DetailsPageRuntimeContext: Context<DetailsPageAppProps> = RuntimeContext

export const connect = typedConnect<DetailsPageAppProps>()

export function usePageState<T>(selector: (state: State) => T): T {
  return selector(React.useContext(DetailsPageRuntimeContext).state)
}

export const usePageActions = () => React.useContext(DetailsPageRuntimeContext).actions
